import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { PersonService } from '../../services/person.service';
import { GroupService } from '../../services/group.service';

import { AddMemberComponent } from '../add-member/add-member.component';
import { AddGroupComponent } from '../add-group/add-group.component';

import { fromEvent, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit, OnDestroy {

  private _groups: number = 0; // private property _groups

  // use getter setter to define the property
  get groups(): any {
    return this._groups;
  }

  @Input()
  set groups(val: any) {
    this._groups = val;

    setTimeout(() => {
      this.admin = (this.admin || this.getMyGroupStatus(
        this.groups.find(g => (this.homecell ? [0, 1, 2, 5] : [0, 3, 4]).indexOf(g.type) !== -1)));
      this.groupAdmin = this.type === -1 && this.admin;
    }, 20);
  }

  @Input() homecell: boolean = true;
  @Input() type: number = -1;
  @Input() admin: boolean = false;
  @Input() groupAdmin: boolean = false;
  @Input() options: any;

  edit = false;

  private backbuttonSubscription: Subscription;

  constructor(
    public personService: PersonService,
    private groupService: GroupService,
    private modalCtrl: ModalController,
    private router: Router,
    private alertCtrl: AlertController) {

  }

  ngOnInit() {

    const that = this;
    this.backbuttonSubscription = this.router.events.subscribe(async () => {
      const modal = await that.modalCtrl.getTop();

      if (modal) {
        modal.dismiss();
      }
    });
  }

  ngOnDestroy() {
    this.backbuttonSubscription.unsubscribe();

    this.overrideBackButton(null);
  }

  getMyGroupPerson(group: any) {
    return group && group.groupPersons.find(gp => gp.person.id === this.personService.person.id);
  }

  getMyGroupStatus(group: any) {
    const groupPerson = this.getMyGroupPerson(group);

    return groupPerson && (this.type === -1 ?
      // 4 = Team Leader in ministry
      // 6 = Co-ordinator in homecell
      // 9 = 3IC
      (this.homecell ? 6 : 4) :
      (this.homecell ? 9 : 4)) > groupPerson.status;
  }

  isMyGroup(group: any) {
    return !!this.getMyGroupPerson(group);
  }

  showGroup(group: any) {
    if (this.groups.length === 1) {
      return true;
    }

    if (this.groups[0].type === 0) {
      return group === this.groups[0];
    }

    return true;
  }

  async addPerson(group: any) {
    const modal = await this.modalCtrl.create({
      component: AddMemberComponent,
      componentProps: {
        group
      }
    });
    return await modal.present();
  }

  async addGroup(group: any) {
    const modal = await this.modalCtrl.create({
      component: AddGroupComponent,
      componentProps: {
        parentGroup: group
      }
    });
    return await modal.present();
  }

  async deleteGroup(group: any) {

    if (group.groupPersons.length !== 0) {
      return;
    }

    if (Object.prototype.toString.call(this._groups) !== '[object Array]') {
      return;
    }

    this.groupService.deleteGroup(group.id).pipe(take(1)).subscribe(
      () => {
        // this._groups = this._groups.filter(g => typeof g === 'object' && g.id !== group.id);
      }
    );
  }

  async deleteConfirm(group: any) {
    const alert = await this.alertCtrl.create({
      message: 'Are you sure you want to delete this group?',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            console.log('Delete clicked');
            return this.deleteGroup(group);

          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    alert.present();
    return;
  }

  // async clickPerson(addPerson) {

  //   const modal = await this.modalCtrl.create({
  //     component: AddMemberComponent,
  //     componentProps: {
  //       addPerson
  //     }
  //   });
  //   console.log("open modal here")
  //   return await modal.present();
  // }

  trackGroup(index: number, group: any) {
    return group ? group.id : null;
  }

  @HostListener('document:ionBackButton', ['$event'])
  @HostListener('window:popstate', ['$event'])
  @HostListener('window:pushstate', ['$event'])
  public async overrideBackButton($event: any) {
    const modal = await this.modalCtrl.getTop();

    if (modal) {
      modal.dismiss();
    }
  }
}
