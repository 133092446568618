import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Person } from './person.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // loggedIn: boolean = false;
  user: any;
  userId = 0;
  token: string;

  loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(public http: HttpClient) {
    const token = localStorage.getItem('token');

    if (token)  { // TODO: Check expirey on Token...
      this.user = this.parseJwt(token);
      this.userId = this.user.id;

      this.loggedIn$.next(true);
    }
  }
  get loggedIn(): boolean {
    const token = localStorage.getItem('token');
    // TODO: Check expirey on Token...
    return token !== null && token.length > 0;
  }


  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  authenticateUser(email: string, password: string) {
    console.log('auth', `${environment.apiHost}/api/user/login`);
    return this.http.post(`${environment.apiHost}/api/user/login`, {email, password})
      .pipe(
        tap((res: any) => {
          if (res.success) {
            // this.loggedIn = true;
            this.user = this.parseJwt(res.token);
            this.userId = this.user.id;
            this.token = res.token;
            if (this.token.indexOf('JWT ') === 0) {
              this.token = this.token.split(' ')[1];
            }
            localStorage.setItem('token', this.token);
            // this.loggedIn$.next(true);
            console.log(this.user);
          }

          // console.log(parseJwt(res.token).id, 'Here is the id');
          return res;
        })

      );


  }

  resetUser(hash: string, newPassword: string, confirmPassword: string) {
    return this.http.post(`${environment.apiHost}/api/user/reset`, {hash, newPassword, confirmPassword});
  }

  registerUser(person: Person) {
    return this.http.post(`${environment.apiHost}/api/user/register`, person);
  }

  logOut() {
   localStorage.removeItem('token');
  //  this.loggedIn$.next(false);
  //  this.loggedIn = false;
  }

  inviteUser(email: string) {
    return this.http.post(`${environment.apiHost}/api/user/invite`, {email}).subscribe(_ => {});
  }

  forgotPassword(email: string) {
    return this.http.post(`${environment.apiHost}/api/user/forgot`, {email}).subscribe(_ => {});
  }

}
