import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GroupService } from 'src/app/services/group.service';
import { PersonService, Person } from 'src/app/services/person.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ministry-list',
  templateUrl: './ministry-list.component.html',
  styleUrls: ['./ministry-list.component.scss'],
})
export class MinistryListComponent implements OnInit {

  @Input() parent: number;
  @Input() group = {name: '', id: 0};
  @Input() Group;

  x:number = 0;
  groupID: number = -1;
  groupPersonId = -1;
  subs: Subscription;
  ministries$: Observable<any>;

  constructor(private groupService: GroupService, 
    public personService: PersonService) {
  }

  ngOnInit() {

    // const getGroupPerson = () => {
    //   const myGroupPerson = this.personService.groupPersons.filter(myUser => {
    //     // console.log("myUser", myUser.person.id, this.PersonService.person.id)
    //     return myUser.person.id === this.personService.person.id;
    //   });
    //   // console.log("myGroupPerson", myGroupPerson, this.PersonService.person.groupPerson)
    //   this.groupPersonId = myGroupPerson && myGroupPerson[0] && myGroupPerson[0].id;
    //   // console.log('GroupPersonId', this.groupPersonId);
    // };

    // // console.log("myUser", this.personService.person.groupPerson)
    // if (this.personService.person) {
    //   getGroupPerson();
    // }else {
    //   this.subs = this.personService.onLoad.subscribe(getGroupPerson);
    // }

    const filterChildren = (group) => {
      if (group.children) {
        group.children = group.children
          .filter(filterChildren)
          .filter(grp => grp.type !== 0);
      }

      if (group.type === 0) {
        return group.children;
      }

      // console.log(this.personService.ministry, group);
      group.joined = this.personService.ministry.findIndex(ministry => ministry.id === group.id) !== -1;

      
      if(group.joined){
         this.x++;

         console.log(group.id, this.groupPersonId);
        this.groupID=group.id;
      }
      

      return [3, 4].indexOf(group.type) !== -1;
    };

    this.ministries$ = this.groupService.getGroup(this.parent)
      .pipe(map(filterChildren));

    // this.ministries$ = this.groupService.getGroups()
    // .pipe(map(groups => groups.filter(group => group.type === 3)));

  }

  async joinMinistry(groupId: number) {
    await this.personService.updateGroupPerson(null, {
      person: this.personService.person.id,
      group: groupId,
      status: 6 // trainee is 6
    });
  }

  async leaveMinistry(){
    

    const getGroupPerson = () => {
      const myGroupPerson = this.personService.groupPersons.filter(myUser => {
        // console.log("myUser", myUser.person.id, this.PersonService.person.id)
        return myUser.person.id === this.personService.person.id;
      });
      // console.log("myGroupPerson", myGroupPerson, this.PersonService.person.groupPerson)
      this.groupPersonId = myGroupPerson && myGroupPerson[0] && myGroupPerson[0].id;
      // console.log('GroupPersonId', this.groupPersonId);
    };

    // console.log("myUser", this.personService.person.groupPerson)
    if (this.personService.person) {
      getGroupPerson();
    }else {
      this.subs = this.personService.onLoad.subscribe(getGroupPerson);
    }

    console.log(this.groupPersonId);
    await this.personService.removeGroupPerson(this.groupPersonId);
  }
}
