import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';

import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {

  hash: string;
  password: string;
  confirmPassword: string;

  constructor(
    private alertCtrl: AlertController,
    private route: ActivatedRoute,
    private loadingCtrl: LoadingController,
    private auth: AuthenticationService,
    private router: Router
  ) { 
    this.route.params.subscribe( params => {
      console.log('params', params, params.hash);
      this.hash = params.hash;
    } );
    console.log('working'); }

  ngOnInit() {}
  async reset(){
    if (this.hash === '' || this.password === '' || this.confirmPassword === '') {
      const alert = await this.alertCtrl.create({
        message: 'Registration Error',
        subHeader: 'All fields are rquired',
        buttons: ['OK']
      });
      alert.present();
      return;
    }

    
    const loading = await this.loadingCtrl.create({
      message: 'Registering...'
    });
    loading.present();
    console.log('Done this step 1', this.password, this.confirmPassword);
    this.auth.resetUser(this.hash, this.password, this.confirmPassword)
      .subscribe((result) => {

      loading.dismiss();
      this.router.navigate(['/']);
      // this.navCtrl.setRoot(MainPage);
      console.log('Done this step 2');
    }, async (err) => {
      loading.dismiss();
      console.log(err);

      const alert = await this.alertCtrl.create({
        message: 'Registration Error',
        subHeader: 'Error',
        buttons: ['OK']
      });
      alert.present();
    });
  }

}
