import { Component, OnInit } from '@angular/core';

import { AlertController, LoadingController } from '@ionic/angular';

import { AuthenticationService} from '../../services/authentication.service';


import { Router } from '@angular/router';
import { PersonService } from 'src/app/services/person.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  email: string = '';
  constructor(
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private auth: AuthenticationService,
    private personService: PersonService,
    private router: Router) { }

  ngOnInit() {}


  async SendEmail(){
    console.log(this.email);
    

    if (this.email === '') {
      const alert = await this.alertCtrl.create({
        message: 'Email Error',
        subHeader: 'Please enter your Email',
        buttons: ['OK']
      });
      alert.present();
      return;
    }else{
      const alert = await this.alertCtrl.create({
        message: 'Email Sent',
        subHeader: 'Please check your Emails',
        buttons: ['OK']
      })
    }
    this.auth.forgotPassword(this.email);
  
  }
}
