import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { PersonService } from 'src/app/services/person.service';
import { AttendanceService } from 'src/app/services/attendance.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {


    constructor(
    private authService: AuthenticationService,
    private _router: Router,
    private structure: PersonService,
    private attendance: AttendanceService
  ) { }

  logOut() {
    this.authService.logOut();
    this.structure.logOut();
    this.attendance.logOut();
    this._router.navigate(['/']);
  }

  pages = [
    {
      title:  'Home',
      url:  '/',
      icon: 'home'
    },
    {
      title:  'My Church',
      url:  '/church',
      icon: 'contacts'
    },
    {
      title:  'Homecell',
      url:  '/homecell',
      icon: 'contacts'
    },
    {
      title:  'Ministry',
      url:  '/ministry',
      icon: 'time'
    },
    {
      title:  'About',
      url:  '/about',
      icon: 'help'
    },
    {
      title:  'Giving',
      url:  '/giving',
      icon: 'wallet'
    },
    {
      title:  'Settings',
      url:  '/settings',
      icon: 'settings'
    }



    // To Do Later

    // ,
    // {
    //   title:  'Giving',
    //   url:  '/giving',
    //   icon: 'wallet'
    // },
    // {
    //   title:  'Goals',
    //   url:  '/goals',
    //   icon: "trending-up"
    // },
    // {
    //   title:  'Sermons',
    //   url:  '/sermons',
    //   icon: 'book'
    // },
    // {
    //   title:  'Stats',
    //   url:  '/stats',
    //   icon: 'stats'
    // },
    // {
    //   title:  'Resources',
    //   url:  '/resources',
    //   icon: 'paper'
    // },
    // {
  ];


  ngOnInit() {
  }


}
