import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


import { MenuPageModule } from './pages/menu/menu.module';

const routes: Routes = [
  { path: '', loadChildren: () => MenuPageModule },
  /*{ path: 'stats', loadChildren: './pages/stats/stats.module#StatsPageModule' },
  { path: 'giving', loadChildren: '/pages/giving/giving.module#GivingPageModule' },
  { path: 'goals', loadChildren: './pages/goals/goals.module#GoalsPageModule' },

  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  */
  { path: 'welcome', loadChildren: './landingpage/welcome/welcome.module#WelcomePageModule' },
  // { path: 'register', loadChildren: './landingpage/register/register.module#RegisterPageModule' },
  // { path: 'forgot-pasword', loadChildren: './pages/forgot-pasword/forgot-pasword.module#ForgotpaswordPageModule' },
  // { path: 'about', loadChildren: './landingpage/about/about.module#AboutPageModule' },
  // { path: 'homecell-attendance', loadChildren: './pages/homecell/attendance/attendance.module#AttendancePageModule' },
  // { path: 'jim-track', loadChildren: './pages/homecell/jim-track/jim-track.module#JimTrackPageModule' },
  // { path: 'add-volunteer', loadChildren: './pages/ministry/edit-volunteers/edit-volunteers.module#EditVolunteersPageModule' },
  // { path: 'add-member', loadChildren: './pages/homecell/edit-members/edit-members.module#EditMembersPageModule' },
  // { path: 'edit-member/:memberId', loadChildren: './pages/homecell/edit-members/edit-members.module#EditMembersPageModule' },

  // { path: 'contact-detail', loadChildren: './pages/contact-detail/contact-detail.module#ContactDetailPageModule' },
  // { path: 'schedule', loadChildren: './pages/ministry/schedule/schedule.module#SchedulePageModule' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
