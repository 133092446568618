import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { PersonService } from '../../services/person.service';
import { GroupService } from '../../services/group.service';

@Component({
  selector: 'app-homecell',
  templateUrl: './homecell.page.html',
  styleUrls: ['./homecell.page.scss'],
})
export class HomecellPage {
  showButton = false;

  constructor(public personService: PersonService,
    public groupService: GroupService) {;
  }

  ngOnInit () {

  }

  async join(){
    if(this.showButton === false){
      return this.showButton = true;
    }else{
      return this.showButton = false;
    }
  }

  async sendRequest(){
    //send a request to admins or pastor so they can add you to a homecell!
    console.log("add me to a homecell!");
  }

}