import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { BehaviorSubject, Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';

declare var localStorage: any;

export interface Person {
  id?: number;
  email?: string;
  fullName: string;
  cellphone?: string;
  address?: string;
  gender?: number; // 0 = male, 1 = female
  birthday?: Date;
  nmo?: boolean;
  john316?: boolean;
  disc?: boolean;
  hs?: boolean;
  h2o?: boolean;
  discPersonality?: string;
  active?: boolean;
  created?: Date;
  updated?: Date;
  lastLogin?: Date;
  firstAppearance: Date;
  loginAttempt?: number;
  groupPersons?: any[];
  groupPerson?: number;
  group?: number;
  status?: number;
  password?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PersonService implements OnDestroy {

  public person: Person;
  public people: Person[];
  public groupPersons: any[];
  public homecell: any;
  public churches: any;
  public ministry: any;

  public onLoad: EventEmitter<any> = new EventEmitter();

  // public structure = {} as any;

    // - We set the initial state in BehaviorSubject's constructor
  // - Nobody outside the Store should have access to the BehaviorSubject
  //   because it has the write rights
  // - Writing to state should be handled by specialized Store methods (ex: addTodo, removeTodo, etc)
  // - Create one BehaviorSubject per store entity, for example if you have TodoGroups
  //   create a new BehaviorSubject for it, as well as the observable$, and getters/setters
  private readonly _structure = new BehaviorSubject<any>({});

  // Expose the observable$ part of the _structure subject (read only stream)
  readonly structure$ = this._structure.asObservable();

  // the getter will return the last value emitted in _structure subject
  public get structure(): any {
    return this._structure.getValue();
  }

  // assigning a value to this.structure will push it onto the observable
  // and down to all of its subsribers (ex: this.structure = {})
  public set structure(val: any) {
    this._structure.next(val);
  }

  private subs: Subscription;

  constructor(
    private auth: AuthenticationService,
    private http: HttpClient,
    private router: Router
    ) {
    this.auth.loggedIn$.subscribe(_ => {
      if (_) {
        this.getStructure();
      }
    });
 
    this.subs = this.structure$.subscribe(structure => {
      this.person = structure;
      this.homecell = [];
      this.churches = [];
      this.ministry = [];
      const people = [];
      const groupPersons = [];

      people.push(structure);

      // tslint:disable-next-line:no-shadowed-variable
      function traverse(structure) {
        if (structure.groupPersons) {
          for (const groupPerson of (structure as any).groupPersons) {
            if (groupPerson.person) {
              groupPersons.push(groupPerson);
              groupPerson.person.groups = groupPerson.id;
              people.push(groupPerson.person);
            } else {
              groupPersons.push(...groupPerson.group.groupPersons);
            }

            if (groupPerson.group && groupPerson.group.children) {
              for (const child of groupPerson.group.children) {
                traverse(child);
              }
            }
          }
        }
        if (structure.children) {
          for (const child of (structure as any).children) {
            traverse(child);
          }
        }
      }
      traverse(structure);

      const sortById = (a, b) => a.id > b.id ? 1 : -1;

      this.people = JSON.parse(JSON.stringify(people.filter((item, index) => people
        .findIndex(obj => obj.id === item.id) === index)
        .sort(sortById)));

      this.groupPersons = groupPersons;

      if (structure.groupPersons && structure.groupPersons.length) {
        const homecells = structure.groupPersons
          .filter(groupPerson => [0, 2, 5, 6].indexOf(groupPerson.group.type) !== -1)
          .map(gp => gp.group);

        const ministry = structure.groupPersons
          .filter(groupPerson => [0, 3, 4].indexOf(groupPerson.group.type) !== -1)
          .map(gp => gp.group);

        const church = structure.groupPersons
          .filter(groupPerson => [0].indexOf(groupPerson.group.type) !== -1)
          .map(gp => gp.group);

        // const homecel = structure.groupPersons
        //   .filter(groupPerson => [5].indexOf(groupPerson.group.type) !== -1)
        //   .map(gp => gp.group);

        const ministries = structure.groupPersons
          .filter(groupPerson => [3].indexOf(groupPerson.group.type) !== -1)
            .map(gp => gp.group);

        this.homecell = JSON.parse(JSON.stringify(homecells));
        this.ministry = JSON.parse(JSON.stringify(ministry));
        this.churches = JSON.parse(JSON.stringify(church));

        function filterChildren(group, types) {

          if (group.children) {
            group.children = group.children
              .filter(child => filterChildren(child, types))
              .sort(sortById);
          }

          return types.indexOf(group.type) !== -1;
        }

        for (const homecell of this.homecell) {
          filterChildren(homecell, [0, 1, 2, 5, 6]);
        }

        // tslint:disable-next-line:no-shadowed-variable
        for (const ministry of this.ministry) {
          filterChildren(ministry, [0, 3, 4]);
        }

        for (const churches of this.churches) 
          filterChildren(churches, [0]);{
        }

      }
      this.onLoad.emit();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  updateJimTrack() {
    throw new Error('Method not implemented.');
  }
  getstructure(): any {
    throw new Error('Method not implemented.');
  }

  getStructure() {
    if (!this.auth.loggedIn) {
      this.router.navigate(['/login']);
      return;
    }
    // console.log('getStructure');
    // Make sure all the peoples are belong to us
    // this.auth.userId;

    let tmp = localStorage.getItem('structure');

    if (tmp) {
      tmp = JSON.parse(tmp);
      if (tmp.id === this.auth.userId) {
        this.structure = tmp;
      }
    }

    this.http.get(`${environment.apiHost}/api/users/${this.auth.userId}/structure`).toPromise()
      .then(structure => {
        this.structure = structure;
        localStorage.setItem('structure', JSON.stringify(this.structure));
      })
      .catch(e => console.error('Error fetching structure', e));
  }

  updatePerson(id: number, newPerson: Person) {
    let person = this.people.find(obj => obj.id === id);

    if (!person) {
      person = {
        fullName: '',
        address: '',
        nmo: false,
        john316: false,
        disc: false,
        hs: false,
        h2o: false,
        active: false,
        firstAppearance: new Date(),
      };
      this.people.push(person);
    }

    Object.assign(person, newPerson);

    this.http.post(`${environment.apiHost}/api/users`, person).toPromise()
      .then(() => this.getStructure())
      .catch(e => console.error('Error updating user', e));

  }

  updateGroupPerson(id: number, groupPerson: any) {
    let gp = this.groupPersons.find(obj => obj.id === id);

    if (!gp) {
      gp = {
        group: 0,
        person: 0,
        status: 99,
      };
    }

    Object.assign(gp, groupPerson);

    console.log('gp', groupPerson);

    this.http.post(`${environment.apiHost}/api/groupPerson`, gp).toPromise()
      .then(() => this.getStructure())
      .catch(e => console.error('Error updating groupPerson', e));

  }

  removeGroupPerson(id: number) {

    const observable = this.http.delete(`${environment.apiHost}/api/groupPerson/${id}`);

    observable.subscribe(
        () => this.getStructure(),
        e => console.error('Error updating groupPerson', e));

    return observable;

  }

  logOut() {

    localStorage.removeItem('structure');

   //  this.loggedIn = false;
   }
}

