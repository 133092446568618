import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { MatExpansionModule } from '@angular/material/expansion';

import { MenuPage } from './menu.page';

import { MainPage } from '../main/main.page';
import { LoginPage } from '../login/login.page';
import { RegisterPage } from '../register/register.page';
import { HomecellPage } from '../homecell/homecell.page';
import { AboutPage } from 'src/app/pages/about/about.page';
import { GivingPage } from '../giving/giving.page';
import { SettingsPage } from '../settings/settings.page';

import { MembersComponent } from '../../shared/members/members.component';
import { MemberAttendanceComponent } from '../../shared/member-attendance/member-attendance.component';
import { StructureComponent } from '../../shared/structure/structure.component';
import { AddMemberComponent } from '../../shared/add-member/add-member.component';
import { AddGroupComponent } from '../../shared/add-group/add-group.component';
import { AddPersonToGroupComponent } from '../../shared/add-person-to-group/add-person-to-group.component';
import { MemberJimTrackComponent } from '../homecell/jim-track/member-jim-track/member-jim-track.component';
import { SermonsPage } from '../sermons/sermons.page';
import { MinistryPage } from '../ministry/ministry.page';

import { AttendancePage } from '../../shared/attendance/attendance.page';
import { EditMembersPage } from '../homecell/edit-members/edit-members.page';
import { JimTrackPage } from '../homecell/jim-track/jim-track.page';
import { MinistryAttendancePage } from '../ministry/ministry-attendance/ministry-attendance.page';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { SignupComponent } from '../signup/signup.component';
import { ResetComponent } from '../reset/reset.component';
import { MyChurchComponent  } from '../my-church/my-church.component';
import { MinistryListComponent } from '../my-church/ministry-list/ministry-list.component';
// import { EditVolunteersPage } from '../ministry/edit-volunteers/edit-volunteers.page';
// import { SchedulePage } from '../ministry/schedule/schedule.page';

import { SortByPipe } from '../../shared/sort-by.pipe';

const routes: Routes = [
  {
    path: '',
    component: MenuPage,
    children: [
      { path: '', component: MainPage },
      { path: 'homecell', component: HomecellPage },
      { path: 'login', component: LoginPage},
      { path: 'register', component: RegisterPage},
      { path: 'register/:hash', component: RegisterPage},
      { path: 'ministry', component: MinistryPage },
      { path: 'settings', component: SettingsPage },
      { path: 'about', component: AboutPage },
      { path: 'giving', component: GivingPage },
      { path: 'forgot', component: ForgotPasswordComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'reset', component: ResetComponent},
      { path: 'reset/:hash', component: ResetComponent},
      { path: 'church', component: MyChurchComponent},
      // { path: 'ministries', component: MinistryListComponent},



      // To Do Later

      // { path: 'sermons', component: SermonsPage },
      // { path: 'stats', loadChildren: '../stats/stats.module#StatsPageModule' },
      // { path: 'giving', loadChildren: '../giving/giving.module#GivingPageModule' },
      // { path: 'goals', loadChildren: '../goals/goals.module#GoalsPageModule' },
      // { path: 'resources', loadChildren: '../resources/resources.module#ResourcesPageModule' },
      // { path: 'settings', loadChildren: '../settings/settings.module#SettingsPageModule' },

      { path: 'homecell-attendance', component: AttendancePage},
      { path: 'edit-members', component: EditMembersPage},
      { path: 'edit-members/:groupPersonId', component: EditMembersPage},
      { path: 'edit-volunteers', component: EditMembersPage},
      { path: 'edit-volunteers/:groupPersonId', component: EditMembersPage},
      { path: 'new-member/:groupId', component: EditMembersPage},
      { path: 'jim-track', component: JimTrackPage},
      { path: 'ministry-attendance', component: AttendancePage},
      // { path: 'edit-volunteers', component: EditVolunteersPage},
      // { path: 'edit-volunteers/:groupPersonId', component: EditVolunteersPage},
      // { path: 'schedule', component: SchedulePage}
    ]

  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    MatExpansionModule
  ],
  declarations: [
    MenuPage,
    MainPage,
    HomecellPage,
    AboutPage,
    GivingPage,
    SettingsPage,
    LoginPage,
    RegisterPage,
    MembersComponent,
    MemberAttendanceComponent,
    StructureComponent,
    MemberJimTrackComponent,
    AddMemberComponent,
    AddGroupComponent,
    AddPersonToGroupComponent,
    SermonsPage,
    MinistryPage,
    AttendancePage,
    EditMembersPage,
    JimTrackPage,
    MinistryAttendancePage,
    ForgotPasswordComponent,
    SignupComponent,
    ResetComponent,
    MyChurchComponent,
    MinistryListComponent,
    // EditVolunteersPage,
    // SchedulePage,
    SortByPipe],
  entryComponents: [
    AddMemberComponent,
    AddGroupComponent,
    AddPersonToGroupComponent
  ]
})
export class MenuPageModule {

  MenuPage: MenuPageModule;
}
