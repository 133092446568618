import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
// import { Router } from '@angular/router';
import { PersonService } from 'src/app/services/person.service';
import { GroupService } from 'src/app/services/group.service';
import { Subscription } from 'rxjs';
// import { AttendanceService } from 'src/app/services/attendance.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.page.html',
  styleUrls: ['./main.page.scss'],
})
export class MainPage implements OnInit, OnDestroy {

  groupPersonId = -1;

  subs: Subscription;

  constructor(
    public authService: AuthenticationService,
    public personService: PersonService,
    private groupService: GroupService) { }

  ngOnInit() {
    const getGroupPerson = () => {
      const myGroupPerson = this.personService.groupPersons.filter(myUser => {
        // console.log("myUser", myUser.person.id, this.PersonService.person.id)
        return myUser.person.id === this.personService.person.id;
      });
      // console.log("myGroupPerson", myGroupPerson, this.PersonService.person.groupPerson)
      this.groupPersonId = myGroupPerson && myGroupPerson[0] && myGroupPerson[0].id;
      console.log('GroupPersonId', this.groupPersonId);
    };

    // console.log("myUser", this.PersonService.person.groupPerson)
    if (this.personService.person) {
      getGroupPerson();
    } else {
      this.subs = this.personService.onLoad.subscribe(getGroupPerson);
    }
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
  // myUser() {

  //   this.PersonService.person.fullName

  // }

  // logOut() {
  //   this.authService.logOut();
  //   this.structure.logOut();
  //   this.attendance.logOut();
  //   this._router.navigate(['/']);
  // }
}
