/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  transform(value: any[], column: string = ''): any[] {
    if (!value) { return value; } // no array
    if (!column || column === '') { return value.sort(); } // sort 1d array
    if (value.length <= 1) { return value; } // array with only one item
    return value.sort((a, b) => a[column] > b[column] ? 1 : -1);
  }
}
