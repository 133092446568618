import { Component, OnInit } from '@angular/core';
import { PersonService, Person } from '../../../services/person.service';

@Component({
  selector: 'app-jim-track-page',
  templateUrl: './jim-track.page.html',
  styleUrls: ['./jim-track.page.scss'],
})
export class JimTrackPage {

  members: Person[];
  constructor(public personService: PersonService) { }

  // updateMember(member: Person) {
  //   this.personService.updatePerson(member.id, member);

  //   console.debug('member updated:', member);
  // }
}






