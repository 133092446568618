import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription, BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  private readonly _attendance = new BehaviorSubject<any>([]);
  private readonly _filteredAttendance = new BehaviorSubject<any>([]);

  // Expose the observable$ part of the _structure subject (read only stream)
  readonly attendance$ = this._attendance.asObservable();
  readonly filteredAttendance$ = this._filteredAttendance.asObservable();

  private subs: Subscription;

  // the getter will return the last value emitted in _attendance subject
  public get attendance(): any[] {
    return this._attendance.getValue();
  }

  // assigning a value to this.attendance will push it onto the observable
  // and down to all of its subsribers (ex: this.structure = {})
  public set attendance(val: any[]) {
    this._attendance.next(val);

    if (this.filter) {
      this.filteredAttendance = val.filter(this.filter);
    }
  }


  private filter: any;

  public get filteredAttendance(): any[] {
    return this._filteredAttendance.getValue();
  }

  public set filteredAttendance(val: any[]) {
    this._filteredAttendance.next(val);
  }

  constructor(private http: HttpClient) {
    this.getAttendance();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setFilter(filter) {
    if ((filter && filter.toString()) !== (this.filter && this.filter.toString())) {
      console.log('setting filter..');
      this.filteredAttendance = this.attendance.filter(filter);
      this.filter = filter;
    }
  }

  getAttendance() {
    console.log('getAttendance');
    const tmp = localStorage.getItem('attendance');

    if (tmp) {
      this.attendance = JSON.parse(tmp);
    }

    const d = new Date();
    d.setMonth(d.getMonth() - 1);

    this.http.get(`${environment.apiHost}/api/attendance?date=d`).toPromise()
      .then((attendance: any[]) => {
        this.attendance = attendance;
        localStorage.setItem('attendance', JSON.stringify(this.attendance));
      })
      .catch(error => console.error('Error fetching attendance', error));
  }

  getSessions() {
    return (this.filteredAttendance.length ? this.filteredAttendance : this.attendance)
      .map(a => a.session)
      .filter((a, i, self) => self.findIndex(obj => obj.id === a.id) === i);
  }

  updateAttendance(
    date: Date, type: number, person: number,
    attended: boolean, serving: boolean = false) {
    console.log('update attendance', arguments);
    this.http.post(`${environment.apiHost}/api/attendance`, {date, type, person, serving, attended}).toPromise()
      .then(() => this.getAttendance())
      .catch(error => console.error('Error updating user', error));
  }

  logOut() {

    localStorage.removeItem('attendance');

   //  this.loggedIn = false;
   }
}
