import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { AuthenticationService} from '../../services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { PersonService, Person } from 'src/app/services/person.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {

  surname: string = '';
  number: string = '';
  Email: string = '';
  IDnumber: string = '';
  person: Person = {} as Person;

  constructor(
    private alertCtrl: AlertController,
    public personService: PersonService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {}

  async sendActivation(){

    if(this.person.fullName == '' || this.person.email == '' || this.person.cellphone == ''){
      const alert = await this.alertCtrl.create({
        message: 'Sign up Error',
        subHeader: 'Please fill in all the spaces',
        buttons: ['OK']
      });
      alert.present();
      return;
    }
    await this.authService.registerUser(this.person).subscribe(value => console.log(value), error => console.error('Error registering', error));
  }
}
