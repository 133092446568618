import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { PersonService } from 'src/app/services/person.service';
import { AttendanceService } from 'src/app/services/attendance.service';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.page.html',
  styleUrls: ['./attendance.page.scss'],
})
export class AttendancePage implements OnInit {

  @ViewChild('attendanceType', {static: true})
  private attendanceType: ElementRef<HTMLSelectElement>;

  attendance: number = 0;
  sessions: any[] = [];
  filteredSessions: any[] = [];
  dates: any[] = [];
  date: Date = new Date();
  selectedDate: Date = this.date;
  ministry: boolean = false;

  constructor(
    public personService: PersonService,
    private attendanceService: AttendanceService,
    private router: Router) { }

  ngOnInit() {
    this.sessions = this.attendanceService.getSessions();

    this.dates = this.sessions
      .map(obj => obj.date)
      .filter((val, i, self) => self.indexOf(val) === i);

    this.dates = this.dates.map(a => {
        a = new Date(a);
        return [a, a.toDateString()];
      })
      .reverse();
    this.selectDate();

    this.ministry = this.router.url === '/ministry-attendance';

  }

  selectDate() {
    this.filteredSessions = this.sessions.filter(
      session => new Date(session.date).getTime() === this.selectedDate.getTime());

    setTimeout(() => {
      if (this.attendanceType.nativeElement.options[0]) {
        this.attendance = Number(this.attendanceType.nativeElement.options[0].value);
      } else {
        this.selectDate();
      }
    }, 50);
  }

}
