import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';

import { PersonService, Person } from '../../../services/person.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-edit-members',
  templateUrl: './edit-members.page.html',
  styleUrls: ['./edit-members.page.scss'],
})
export class EditMembersPage implements OnInit {

  groupPersonId: number;
  person: Person = {} as Person;
  groupPerson: any = {status: 0};
  groupId: number;
  edit = false;
  permission = true;
  ministry: boolean = false;
  password = true;

  constructor(
    public personService: PersonService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router) {}

  ngOnInit() {
    this.groupPersonId = +this.route.snapshot.paramMap.get('groupPersonId');
    this.groupId = +this.route.snapshot.paramMap.get('groupId');
    // console.log("person.password", this.person)
    console.log('this.ministry', this.ministry);

    if (this.groupId) {
      // console.log('adding new person to group:', this.groupId);
      this.edit = true;
    }


    if (this.groupPersonId) {

      this.groupPerson = this.personService.groupPersons
        .find(gp => gp.id === this.groupPersonId);

        // console.log(this.groupPerson);

      this.permission = (this.groupPerson &&
        this.groupPerson.person.id === this.personService.person.id);

      if (!this.permission) {
        // console.log(this.personService.person.groupPersons);
        this.permission = 7 > this.personService.person.groupPersons
          .find(gp => [0, 1, 2, 5].indexOf(gp.group.type) !== -1).status;
      }

      if (this.groupPerson) {
        this.groupPerson = JSON.parse(JSON.stringify(this.groupPerson));
        this.person = this.groupPerson.person;
        // console.log(this.person);
        // console.log(this.groupPerson);
      }
    }

    this.ministry = this.router.url === '/edit-volunteers';

    // console.log("I am the person", (<any>this.person).password);

  }

  // if (this.password === false) {
  //   console.log("Hellooooo", this.password)
  // }

  // console.log("this.password", this.password)


  ngOnChanges(changes: any) {
    // changes.prop contains the old and the new value...
    console.debug('ngOnChanges', this.person, changes);
  }

  updateDate(event: Date) {
    console.log('date', event);
    this.person.birthday = new Date(event);
  }

  saveMember() {
    this.person.groupPerson = this.groupPerson.id;
    this.person.group = this.groupId;
    this.person.status = this.groupPerson.status;
    this.personService.updatePerson(this.person.id, this.person);

    this.edit = false;
    // console.log('saveMember please', this.person.id);
  }

  sendInvite() {
    // console.log('sendInvite', this.person.email);
    this.authService.inviteUser(this.person.email);
  }

}
