import { Component, OnInit } from '@angular/core';

import { PersonService, Person } from '../../services/person.service';

@Component({
  selector: 'app-ministry',
  templateUrl: './ministry.page.html',
  styleUrls: ['./ministry.page.scss'],
})
export class MinistryPage implements OnInit {

  constructor(public personService: PersonService) { }

  ngOnInit() {
  }

}
