import { Component, OnInit } from '@angular/core';

import { AlertController, LoadingController } from '@ionic/angular';

import { AuthenticationService} from '../../services/authentication.service';

// import { userInfo } from 'os';
import { Router } from '@angular/router';
import { PersonService } from 'src/app/services/person.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  email: string = '';
  password: string = '';
  name: string = '';
  public redirectUrl: any;


  constructor(
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private auth: AuthenticationService,
    private personService: PersonService,
    private router: Router) { }

  ngOnInit() {


  }

  async doLogin() {

    if (this.email === '' || this.password === '') {
      const alert = await this.alertCtrl.create({
        message: 'Login Error',
        subHeader: 'All fields are rquired',
        buttons: ['OK']
      });
      alert.present();
      return;
    }

    const loading = await this.loadingCtrl.create({
      message: 'Logging in...'
    });
    loading.present();
    // console.log('Done this step 1')
    this.auth.authenticateUser(this.email, this.password).subscribe((result) => {

      loading.dismiss();
      this.personService.getStructure();
      this.router.navigate(['/']);
      // this.navCtrl.setRoot(MainPage);
      // console.log('Done this step 2')

    }, async (err) => {
      loading.dismiss();
      console.log(err);

      const alert = await this.alertCtrl.create({
        message: 'Login Error',
        subHeader: 'Error',
        buttons: ['OK']
      });
      alert.present();
    });

  }
}


