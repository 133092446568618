import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Person } from 'src/app/services/person.service';
import { AttendanceService } from 'src/app/services/attendance.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-member-attendance',
  templateUrl: './member-attendance.component.html',
  styleUrls: ['./member-attendance.component.scss'],
})
export class MemberAttendanceComponent implements OnInit, OnDestroy {

  @Input() group;
  @Input() ministry: boolean = false;
  @Input() selectedDate: Date = new Date();
  @Input() admin: boolean = false;

  private _attendance: number = 0; // private property _attendance

  // use getter setter to define the property
  get attendance(): any {
    return this._attendance;
  }

  @Input()
  set attendance(val: any) {
    this._attendance = val;
    this.filterAttended();
  }

  public attended: any[] = [];

  private subs: Subscription;

  constructor(private attendanceService: AttendanceService) { }

  ngOnInit() {
    this.attendanceService
      .setFilter(
        ((ministry) => ministry ? (a: any) => a.serving : (a: any) => true)(this.ministry));

    this.filterAttended();
    this.subs = this.attendanceService.filteredAttendance$
      .subscribe(() => this.filterAttended());
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  filterAttended() {
    const filteredAttendance = this.attendanceService.filteredAttendance
      .filter(a => {
        return new Date(new Date(a.session.date).toDateString()) // Hack to get date to work :(
        .getTime() === new Date(this.selectedDate.toDateString()).getTime();
      });

    this.attended = this.group.groupPersons.map(gp => {
      const person = gp.person;
      const filterType = (type) => filteredAttendance.find(a => (
          a.person === person.id &&
          a.session.type === type));

      if (Number(this.attendance) === 0) {
        return [filterType(0), filterType(1), filterType(2), filterType(3)];
      } else {
        return [filterType(Number(this.attendance))];
      }
    });

  }

  trackMember(index: number, member: Person) {
    return member ? member.id : null;
  }

  updateAttendance(value: boolean, person: Person, type: number) {
    console.log('person.id', value, person.id, this.group, this.selectedDate);
    this.attendanceService.updateAttendance(
      this.selectedDate,
      type,
      person.id,
      value,
      this.ministry);
  }

}
