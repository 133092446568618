import { Injectable } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { PersonService } from '../services/person.service';

import { environment } from 'src/environments/environment';

export interface Group {
  id?: number;
  parent?: number;
  name: string;
  description: string;
  type: number;
}

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  public members: Group[];
  public group: Group;
  public groups: Group[];

  private subs: Subscription;

  public get structure(): any {
    return this.structure.getValue();
  }

  constructor(
    private http: HttpClient,
    private personService: PersonService) {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public updateGroup(id: number, newGroup: Group) {
    // let group = this.groups.find(obj => obj.id === id);

    let group;
    if (!group) {
      group = {
        name: '',
        description: ''
      };
      // this.groups.push(group);
    }
    Object.assign(group, newGroup);

    this.http.post(`${environment.apiHost}/api/groups`, group).toPromise()
      .then(() => this.personService.getStructure())
      .catch(e => console.error('Error updating group', e));
  }




  deleteGroup(id: number) {

    const observable = this.http.delete(`${environment.apiHost}/api/group/${id}`);

    observable.subscribe(
        () => this.personService.getStructure(),
        e => console.error('Error deleting group', e));

    return observable;

  }

  getGroups() : Observable<any[]> {
    // console.log('hello');
    return this.http.get<any>(`${environment.apiHost}/api/groups`);
  }

  getGroup(id: number) : Observable<any[]> {
    // console.log('hello group');
    return this.http.get<any>(`${environment.apiHost}/api/groups/${id}`);
  }
  // createGroup(id: number, newGroup: Group) {
  //   let body = JSON.stringify(group);
  //   return this.http.post('${environment.apiHost}/api/group/', body);
  // }

  // updateGroup(id: number, newGroup: Group) {
  //   let body = JSON.stringify(group);
  //   return this.http.put('${environment.apiHost}/api/group/' + group.id, body);
  // }

  // deleteGroup(id: number, newGroup: Group) {
  //   return this.http.delete('${environment.apiHost}/api/group/' + group.id);

  // }
}
