import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-person-to-group',
  templateUrl: './add-person-to-group.component.html',
  styleUrls: ['./add-person-to-group.component.scss'],
})
export class AddPersonToGroupComponent implements OnInit {

  @Input() group = {name: '', type: 100};

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  async closeModal(myData: any) {
    await this.modalCtrl.dismiss(myData);
  }

}
