import { Component, OnInit, Input } from '@angular/core';
import { GroupService, Group } from '../../services/group.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss'],
})
export class AddGroupComponent implements OnInit {
  @Input() parentGroup: Group;

  name: string;
  description: string;
  type: number;


  constructor(
    private groupService: GroupService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    console.log(this.parentGroup);
    // this.filteredGroups = this.groupService.groups;
    // // this.group.description = this.description;
  }

  // getGroups(event: Event) {
  //   this.filteredGroups = this.groupService.groups
  //     .filter(group => group.description.toLowerCase()
  //       .indexOf((event.target as any).value.toLowerCase()) !== -1);
  // }

  addGroup() {
    this.groupService.updateGroup(null, {
      name: this.name,
      description: this.description,
      parent: this.parentGroup.id,
      type: this.type
    });
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
