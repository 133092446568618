import { Component, OnInit, Input } from '@angular/core';
import { PersonService, Person } from '../../../../services/person.service';


@Component({
  selector: 'app-member-jim-track',
  templateUrl: './member-jim-track.component.html',
  styleUrls: ['./member-jim-track.component.scss'],
})
export class MemberJimTrackComponent implements OnInit {

  edit = false;
  permission = true;

  @Input() members;
  @Input() jimTrack: boolean;
  @Input() selected: boolean;


  constructor(
    public personService: PersonService) {

  }

  ngOnInit() {}

  updatePerson(person) {
    console.log('gets here??!?!', person);
    this.personService.updatePerson(person.id, person);

    this.edit = false;
    console.log('saveMember please', person.id);
  }


  // updatePerson(member: Person) {
  //   this.personService.updatePerson(member.id, member);

  //   console.debug('member updated:', member);
  // }


  // updateJimTrack(value: boolean, person: Person) {
  //   console.log("person.nmo", value, person.nmo);
  //   this.personService.updateJimTrack(
  //     this.selected = (
  //     person.nmo,
  //     person.john316,
  //     person.disc,
  //     person.hs,
  //     person.h2o) =>

  //     return


  // }



}
