import { Component, OnInit, Input, ChangeDetectorRef  } from '@angular/core';
import { PersonService } from '../../services/person.service';
import { GroupService } from '../../services/group.service';
import { stringify } from 'querystring';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent implements OnInit {

  @Input() group;
  @Input() homecell: boolean = true;
  @Input() edit: boolean = false;
  @Input() admin: boolean = false;

  markedForUpdate: number[] = [];

  constructor(
    public personService: PersonService,
    private groupService: GroupService,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {}

  removeGroupPerson(groupPersonId: number) {
    this.personService.removeGroupPerson(groupPersonId);
  }

  markForUpdate(id: number) {
    this.markedForUpdate.push(id);
  }

  saveGroupPersons() {

    for (const id of this.markedForUpdate) {
      for (const groupPerson of this.group.groupPersons) {
        if (groupPerson.id === id) {
          this.personService.updateGroupPerson(id, groupPerson);
        }
      }
    }
    return this.groupService.updateGroup(this.group.id, this.group);
  }
}
