import { Component, OnInit } from '@angular/core';
import { PersonService } from '../../services/person.service';
import { GroupService } from 'src/app/services/group.service';
// import { AuthenticationService } from 'src/app/services/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-my-church',
  templateUrl: './my-church.component.html',
  styleUrls: ['./my-church.component.scss'],
})
export class MyChurchComponent implements OnInit {

  showlist = false;
  showHide = "Show Ministries";
  churches$: Observable<any>;
 
  constructor(
    public personService: PersonService,
    public groupService: GroupService) {
    personService.getStructure();
  }
  
  ngOnInit() {
    this.churches$ = this.groupService.getGroups()
      .pipe(map(groups => groups.filter(group => group.type === 0)));
  }

  async showMinistries(){
    if(this.showlist === false){
      this.showlist = true;
      this.showHide = 'Hide Ministries';
      return this.showlist,this.showHide;
    }else{
      this.showHide = 'Show Ministries';
      return this.showlist = false;
    }
  }

  async sendRequest(groupId: number) {
    await this.personService.updateGroupPerson(null, {
      person: this.personService.person.id,
      group: groupId,
      status: 12 // FTV is 12
    });
  }
}
