import { Component, OnInit, Input } from '@angular/core';
import { PersonService, Person } from '../../services/person.service';

import { AddPersonToGroupComponent } from '../../shared/add-person-to-group/add-person-to-group.component';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss'],
})
export class AddMemberComponent implements OnInit {

  @Input() group = {name: '', id: 0};

  dataReturned: any;

  filteredPeople: any[] = [];

  constructor(
    private personService: PersonService,
    private modalCtrl: ModalController) {

     }

  ngOnInit() {
    this.filteredPeople = this.personService.people;
  }

  getPeople(event: Event) {
    this.filteredPeople = this.personService.people
      .filter(person => person.fullName.toLowerCase()
        .indexOf((event.target as any).value.toLowerCase()) !== -1);
  }

  async addPerson(group, person: Person) {
    const modal = await this.modalCtrl.create({
      component: AddPersonToGroupComponent,
      componentProps: {
        group: this.group
      },
      showBackdrop: false

    });

    modal.onDidDismiss().then(async (dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        console.log('Modal Sent Data:', dataReturned);

        // IF VALID DATA, SAVE PERSON, ELSE return...

        if (Number(this.dataReturned)) {
          await this.personService.updateGroupPerson(null, {
            person: person.id,
            group: group.id,
            status: Number(this.dataReturned)
          });
          await modal.dismiss();
        } else {
          alert('bad data...' + this.dataReturned);
        }
      }
    });
    console.log('open modal here');
    return await modal.present();



     // let person of personService =  this. groupPerson.push()
  }


}


